@import '../../styles/themes/_variables.scss';

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $main-bg;
  color: $main-color;
  font-family: 'Roboto', sans-serif;

  .login-header {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    background-color: $soft-bg;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 300px;

    input {
      font-family: 'Roboto', sans-serif;
      padding: 0.75rem;
      margin-bottom: 1rem;
      border: 1px solid $dark-bg;
      border-radius: 4px;
      font-size: 1rem;
      background-color: $dark-bg;
      color: $main-color;

      &::placeholder {
        color: $soft-color;
      }

      &:focus {
        outline: none;
        border-color: $primary-color;
      }
    }

    button {
      font-family: 'Roboto', sans-serif;
      padding: 0.75rem;
      font-size: 1rem;
      color: $main-color;
      background-color: $primary-color;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
    // Ensure toast button styles are not overridden
    .toast-close-button {
      background: none;
      border: none;
      color: $error-color;
      font-size: 1.5rem;
      cursor: pointer;
      padding: 0;
      margin: 0;
      position: absolute;
      top: -0.3rem;
      right: 0rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

@media (max-width: 768px) {
  .login-form {
    width: 90%;
    padding: 1rem;
  }

  .login-header {
    font-size: 1.5rem;
  }
}
