// PassengerCounter.scss

@import '../../styles/base/_global';
@import '../../styles/themes/_variables';

.passenger-counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #6c757d;
  color: $main-color;

  &:last-child {
    border-bottom: none;
  }

  .passenger-info {
    display: flex;
    align-items: center;
    gap: 10px;

    .passenger-icon {
      font-size: 24px;
      color: $main-color;
    }

    .passenger-type {
      font-weight: bold;
      font-size: 16px;
      margin: 0;
    }

    .passenger-age {
      font-size: 14px;
      color: $soft-color;
      margin: 0;
    }
  }

  .passenger-controls {
    display: flex;
    align-items: center;
    gap: 10px;

    .counter-button {
      background-color: $soft-bg;
      border: none;
      border-radius: 50%;
      color: $main-color;
      font-size: 20px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:hover:not(:disabled) {
        background-color: lighten($soft-bg, 5%);
      }
    }

    .counter-value {
      font-size: 16px;
      min-width: 20px;
      text-align: center;
    }
  }
}
