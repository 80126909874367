@import '../../../styles/base/_global';
@import '../../../styles/themes/_variables';

.flight-ticket-advanced {
  max-width: 1200px;
  margin: 40px auto;
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;

  /* Header Section */
  .ticket-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    padding-bottom: 20px;
    border-bottom: 2px solid #dee2e6;

    .booking-info,
    .destination-info {
      text-align: left;
      h1 {
        font-size: 36px;
        color: #333;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 24px;
        color: #007bff;
        margin-top: 10px;
      }
      .status {
        text-transform: capitalize;
        font-weight: bold;
        &.booked {
          color: #28a745;
        }
        &.canceled {
          color: #dc3545;
        }
      }
      p {
        font-size: 18px;
        color: #495057;
      }
    }
  }

  /* Ticket Body */
  .ticket-body {
    display: inline;
    //grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    //gap: 30px;

    .section {
      background-color: #fff;
      border-radius: 12px;
      padding: 20px;
      box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.05);
      margin-bottom: 20px;

      h3 {
        font-size: 22px;
        color: #343a40;
        margin-bottom: 20px;
      }

      .passenger-card,
      .flight-card,
      .pricing-card {
        background-color: #f1f3f5;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        transition: transform 0.3s ease;
        &:hover {
          //transform: scale(1.02);
        }

        p {
          font-size: 18px;
          color: #495057;
          line-height: 1.6;
          strong {
            color: #212529;
          }
        }
      }

      .flight-info {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        gap: 30px;

        .flight-segment {
          display: flex;
          align-items: center;
          .icon {
            margin-right: 10px;
            font-size: 24px;
            color: #17a2b8;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 20px;

    .ticket-header {
      flex-direction: column;
      text-align: center;
      h1 {
        font-size: 28px;
      }
      h2 {
        font-size: 20px;
      }
      p {
        font-size: 16px;
      }
    }

    .ticket-body {
      grid-template-columns: 1fr;
    }
  }
}
