@import '../../styles/themes/_variables.scss';
.menu {
  .item {
    display: flex;
    flex-direction: column;
    gap: 10;
    margin-bottom: 20px;

    .title {
      font-size: 12px;
      font-weight: 200;
      color: $soft-color;
      text-transform: uppercase;
    }
    .listItem {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      border-radius: 5px;

      &:hover {
        background-color: $soft-bg;
      }
    }
  }
}
