@import '../../../styles/base/_global';
@import '../../../styles/themes/_variables';

.flight-search-container {
  background-color: #384256;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.flight-search-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.search-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .dropdown {
    flex: 1;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    .dropdown-toggle {
      width: 100%;
      background-color: #2a3447;
      color: #fff;
      border: none;
      padding: 10px 15px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      display: flex;
      justify-content: space-between; /* Aligns text and arrow on opposite ends */
      align-items: center;
      gap: 5px;

      .passenger-text {
        display: inline; /* Default display */
      }

      @media (max-width: 768px) {
        .passenger-text {
          display: none; /* Hide the "Passenger" word on smaller screens */
        }
      }
    }
  }
}

.input-group {
  display: flex;
  gap: 10px;
  position: relative; /* Make sure the autocomplete dropdown is positioned correctly */
  z-index: 1; /* Ensure the input stays on top */

  & > div {
    flex: 1;
    width: calc(50% - 5px); /* Make inputs match dropdown size with spacing */
  }

  @media (max-width: 768px) {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
}

.explore-button {
  width: 100%;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }
}

.custom-textfield {
  background-color: #2a3447;
  color: white;

  .MuiOutlinedInput-root {
    fieldset {
      border-color: #2a3447;
    }
    &:hover fieldset {
      border-color: white;
    }
    &.Mui-focused fieldset {
      border-color: white;
    }
    .MuiAutocomplete-endAdornment {
      display: none; /* Hide the dropdown arrow */
    }
  }

  .MuiInputBase-input {
    color: white;
  }

  .MuiInputLabel-root {
    color: white;

    &.Mui-focused {
      color: white;
    }
  }

  .MuiFormHelperText-root {
    color: #f44336;
    font-size: 12px;
    margin-top: 5px;
  }

  &.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #f44336;
  }
}

.multi-city-legs {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .input-group {
    display: flex;
    gap: 10px;

    button {
      align-self: center;
    }
  }
}
