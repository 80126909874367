// src/pages/Home/Home.module.scss

@import '../../styles/themes/_variables';

.home-container {
  //padding: $spacing-large;
  background-color: #fff;
  //border-radius: $border-radius;
  //box-shadow: $box-shadow;
  //margin: $spacing-large auto;
  max-width: 800px;

  h1 {
    //color: $primary-color;
  }

  .welcome-message {
    //font-size: $font-size-medium;
    //margin-bottom: $spacing-large;
  }

  .logout-button {
    //background-color: $error-color;
    &:hover {
      //background-color: darken($error-color, 10%);
    }
  }
}
