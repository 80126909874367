@import '../../../styles/base/_global';
@import '../../../styles/themes/_variables';

.passenger-info {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: $form-bg;
  color: $main-color;

  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: $main-color;
  }

  .loading-spinner {
    font-size: 18px;
    color: #fff;
    text-align: center;
    margin: 20px;
  }

  .flight-details {
    background-color: $soft-bg;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;

    .flightCardContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #ddd;
      border-radius: 10px;
      margin: 20px 0;
      width: 100%;

      .flightCard {
        display: flex;
        width: 100%;
        background-color: #fff;
        border: 5px solid transparent;
        border-radius: 10px;
        height: auto;
        min-height: 20vh;
        gap: 2px;
        padding: 5px;
        align-items: stretch;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        .flightInfo {
          flex: 2;
          background-color: #fff;
          color: #333;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          gap: 10px;
          padding: 5px;

          .oneWay {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            gap: 30px;

            .flightSegment {
              display: flex;
              justify-content: space-between;
              padding: 10px 0;
              border-bottom: 1px solid #eee; /* Visual separation between segments */
              margin-bottom: 10px; /* Spacing between segments */

              .airline-logo-container {
                display: flex;
                align-items: center;

                img {
                  width: 100%; /* Adjust as needed */
                  height: auto;
                  margin-right: 10px;
                }

                .airline-code {
                  font-size: 20px; /* Adjust size for readability */
                  font-weight: 500;
                  color: #333;
                }
              }
              .metaInfo {
                display: flex;
                flex-direction: column; /* Ensure the elements are under each other */
                align-items: center; /* Center them horizontally */
                gap: 5px; /* Add some spacing between each element */
                font-size: 16px;
                font-weight: 500;

                .flightDuration {
                  font-size: 14px; /* Adjust the font size */
                  font-weight: 500; /* Emphasize the duration */
                }

                .connectingLine {
                  position: relative; /* For positioning the flight icon */
                  width: 100%;
                  height: 2px; /* Thickness of the line */
                  background-color: #333; /* Line color */
                  display: flex;
                  align-items: center; /* Center dots and flight icon vertically */

                  /* Red dots for stops */
                  .stop-dot {
                    width: 8px; /* Size of the dot */
                    height: 8px;
                    background-color: red;
                    border-radius: 50%;
                    position: relative;
                    margin: 0 10px; /* Spacing between dots */
                  }

                  /* Flight icon at the end */
                  .flight-icon {
                    font-size: 20px; /* Adjust the size if needed */
                    color: #007bff; /* Adjust the color */
                    margin-left: 50px; /* Add spacing if necessary */
                    position: absolute;
                    top: -9px; /* Adjust vertical alignment */
                    transform: rotate(
                      90deg
                    ); /* Rotate the icon by 45 degrees */
                  }
                }

                .stops {
                  font-size: 12px; /* Smaller text for the stops */
                  color: #666; /* Slightly muted color */
                }
              }
              .departureInfo,
              .arrivalInfo,
              .flightClassInfo {
                display: flex;
                flex-direction: column; /* Ensures elements are stacked vertically */
                align-items: center; /* Centers text */
                gap: 2px; /* Space between time and airport code */
              }
              .departureTime,
              .arrivalTime,
              .flightClassTitle {
                font-size: 20px;
                font-weight: bold;
              }
              .departureAirportCode,
              .arrivalAirportCode,
              .flightClassText {
                font-size: 16px;
                font-weight: 500;
                color: #666;
              }
            }
          }
          .return {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            gap: 30px;

            .returnFlightSegment {
              display: flex;
              justify-content: space-between;
              padding: 10px 0;
              border-bottom: 1px solid #eee; /* Visual separation between segments */
              margin-bottom: 10px; /* Spacing between segments */

              .airline-logo-container {
                display: flex;
                align-items: center;

                img {
                  width: 100%; /* Adjust as needed */
                  height: auto;
                  margin-right: 10px;
                }

                .airline-code {
                  font-size: 20px; /* Adjust size for readability */
                  font-weight: 500;
                  color: #333;
                }
              }
              .metaInfo {
                display: flex;
                flex-direction: column; /* Ensure the elements are under each other */
                align-items: center; /* Center them horizontally */
                gap: 5px; /* Add some spacing between each element */
                font-size: 16px;
                font-weight: 500;

                .flightDuration {
                  font-size: 14px; /* Adjust the font size */
                  font-weight: 500; /* Emphasize the duration */
                }

                .connectingLine {
                  position: relative; /* For positioning the flight icon */
                  width: 100%;
                  height: 2px; /* Thickness of the line */
                  background-color: #333; /* Line color */
                  display: flex;
                  align-items: center; /* Center dots and flight icon vertically */

                  /* Red dots for stops */
                  .stop-dot {
                    width: 8px; /* Size of the dot */
                    height: 8px;
                    background-color: red;
                    border-radius: 50%;
                    position: relative;
                    margin: 0 10px; /* Spacing between dots */
                  }

                  /* Flight icon at the end */
                  .flight-icon {
                    font-size: 20px; /* Adjust the size if needed */
                    color: #007bff; /* Adjust the color */
                    margin-left: 50px; /* Add spacing if necessary */
                    position: absolute;
                    top: -9px; /* Adjust vertical alignment */
                    transform: rotate(
                      90deg
                    ); /* Rotate the icon by 45 degrees */
                  }
                }

                .stops {
                  font-size: 12px; /* Smaller text for the stops */
                  color: #666; /* Slightly muted color */
                }
              }
              .returnDepartureInfo,
              .returnArrivalInfo,
              .returnFlightClassInfo {
                display: flex;
                flex-direction: column; /* Ensures elements are stacked vertically */
                align-items: center; /* Centers text */
                gap: 2px; /* Space between time and airport code */
              }
              .returnDepartureTime,
              .returnArrivalTime,
              .returnFlightClassTitle {
                font-size: 20px;
                font-weight: bold;
              }
              .returnDepartureAirportCode,
              .returnArrivalAirportCode,
              .returnFlightClassText {
                font-size: 16px;
                font-weight: 500;
                color: #666;
              }
            }
          }
        }
        .verticalLine {
          width: 1px;
          background-color: #333;
          align-items: stretch;
        }

        .priceSelect {
          flex: 1;
          background-color: #fff;
          color: #333;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;

          .price {
            font-size: 20px;
            font-weight: bold;
            color: #333;
          }
        }
      }
      .extraDetails {
        width: 100%;
        //display: none; /* Hidden by default */
        color: black;
        flex-direction: column;
        padding: 0 10px;
        margin: 10px 0 0 0;

        .basicInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          margin-bottom: 10px;
          //border-bottom: 1px solid #ccc; /* Add a bottom border for clarity */
          padding-bottom: 5px;
        }

        .baggageInfo,
        .fareBreakdown,
        .taxBreakdown {
          margin-top: 10px;

          h4 {
            margin-bottom: 5px;
            font-size: 16px;
            color: #333;
          }

          ul {
            padding-left: 15px;
            list-style: none; /* Remove bullet points */

            li {
              margin-bottom: 5px;
              font-size: 14px;
              color: #555;

              strong {
                font-weight: bold;
                color: #333;
              }
            }
          }
        }

        /* New table styles for fare breakdown */
        .fareTable {
          width: 100%;
          border-collapse: collapse;
          margin-top: 10px;

          th,
          td {
            padding: 8px;
            text-align: left;
            border-bottom: 1px solid #ddd;
            font-size: 14px;
          }

          th {
            background-color: #f4f4f4;
            font-weight: bold;
          }

          td {
            color: #555;
          }
        }

        /* Expand the section when it is clicked/open */
        &.expanded {
          display: flex; /* Display when expanded */
        }

        /* For responsive design */
        @media (max-width: 768px) {
          .fareTable,
          th,
          td {
            font-size: 12px;
            padding: 6px;
          }

          h4 {
            font-size: 14px;
          }

          .baggageInfo ul li {
            font-size: 12px;
          }
        }
      }
    }
    // Fare Rules Dialog Styles
    .fareRulesDialog {
      background-color: red;
      .MuiDialog-paper {
        width: 600px; // Allow it to take up more space on the screen
        max-width: 600px; // Keep the max-width limit for readability
        min-width: 300px; // Set a reasonable min-width to avoid extreme narrowness
        border-radius: 10px; // Smooth corners for a modern look
        background-color: #f9f9f9; // Light background for readability
      }

      .MuiDialogTitle-root {
        font-size: 18px;
        font-weight: bold;
        color: #333;
        text-align: center; // Center the title for better aesthetics
      }

      .MuiDialogContent-root {
        max-height: 400px; // Limit height for long rules
        overflow-y: auto; // Allow scrolling when content exceeds the limit
        padding: 10px 20px;
        color: #555; // Soft color for text content
      }

      .MuiDialogActions-root {
        display: flex;
        justify-content: center;
        padding: 10px;
      }

      .ruleContainer {
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e0e0e0; // Light border between each rule
      }

      .ruleTitle {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 5px;
        color: #007bff; // Highlighted color for rule category
      }

      .ruleContent {
        font-size: 14px;
        color: #555; // Soft color for rule details
      }

      // Responsive Design for Smaller Screens
      @media (max-width: 600px) {
        .MuiDialog-paper {
          padding: 10px;
          width: 100%; // Let it take the full screen width on smaller devices
          max-width: unset; // Remove the max-width restriction on smaller screens
        }
        .ruleTitle {
          font-size: 14px;
        }
        .ruleContent {
          font-size: 12px;
        }
      }
    }
  }

  .passenger-row {
    background-color: $soft-bg;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;

    h3 {
      color: $main-color;
      margin-bottom: 10px;
    }

    .passenger-fields {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      .form-group {
        margin-bottom: 10px;

        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
          color: $soft-color;
        }

        input,
        select {
          width: 100%;
          padding: 10px;
          background-color: $input-bg;
          border: 1px solid $input-border;
          border-radius: 4px;
          color: $main-color;
          transition: border-color 0.3s ease;

          &:focus {
            border-color: $primary-color;
            outline: none;
          }
        }

        // Custom styling for the dropdown (select element)
        select {
          appearance: none;
          background-color: $input-bg;
          color: $main-color;
          border: 1px solid $input-border;
          padding: 10px;
          border-radius: 4px;
          transition: border-color 0.3s ease;
          font-size: 16px;

          &:focus {
            border-color: $primary-color;
          }

          // For consistent appearance across browsers
          &::-ms-expand {
            display: none;
          }
        }

        .error-text {
          color: $error-color;
          font-size: 12px;
        }
      }
    }
  }

  button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: $button-bg;
    color: $main-color;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $button-bg-hover;
    }
  }
}
