// src/pages/expenses/createExpense/ExpenseCreate.scss

@import '../../../styles/base/_global';
@import '../../../styles/themes/_variables';

.expense-create-container {
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: $main-bg;

  .paper {
    padding: 24px;
    background-color: white;
    color: #000000;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }

  h2 {
    margin-bottom: 16px;
    //color: $primary-color;
  }

  .form-group {
    margin-bottom: 16px;
    position: relative;
    text-align: left;

    input,
    select,
    textarea {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 8px;
      font-size: 1rem;
      &:focus {
        border-color: $primary-color;
        outline: none;
      }
    }

    textarea {
      resize: vertical;
    }

    label {
      position: absolute;
      top: -10px;
      left: 10px;
      background-color: white;
      padding: 0 4px;
      font-size: 0.875rem;
      color: $primary-color;
    }
  }

  .submit-button {
    margin-top: 16px;
    background-color: $primary-color;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;

    &:disabled {
      background-color: darken($primary-color, 10%);
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background-color: darken($primary-color, 10%);
    }
  }

  .errorText {
    color: $error-color;
    font-size: 0.875rem;
    margin-top: 4px;
  }
}
