// src/components/passengerSelector/PassengerSelector.scss

@import '../../styles/base/_global';
@import '../../styles/themes/_variables';

.passenger-selector {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: $dark-bg;
  border-radius: 5px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  width: 250px;

  .counter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    .counter-label {
      display: flex;
      align-items: center;
      gap: 5px;
      color: $main-color;

      .icon {
        font-size: 18px;
      }

      .label-text {
        font-size: 14px;
      }
    }

    .counter-controls {
      display: flex;
      align-items: center;
      gap: 10px;

      .control-button {
        background-color: $soft-bg;
        color: $main-color;
        border: none;
        padding: 5px 8px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;

        &:hover {
          background-color: lighten($soft-bg, 5%);
        }

        &:disabled {
          background-color: darken($soft-bg, 5%);
          cursor: not-allowed;
        }
      }
    }
  }
}
