@import '../../../styles/themes/_variables';

.policy-create-container {
  .paper {
    padding: 20px;
    background-color: $soft-bg;
    color: $main-color;
  }
  .step-content {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .rule-box {
    background-color: $main-bg;
    padding: 10px;
    min-height: 200px;
    border: 2px dashed $soft-color;
    .rule-card {
      margin: 10px 0;
      padding: 10px;
      background-color: $dark-bg;
      color: $main-color;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .rule-content {
        display: flex;
        gap: 10px;
        align-items: center;
        flex-grow: 1;
        flex-wrap: wrap; // Ensure content wraps on smaller screens
      }
      .rule-actions {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
  }
  .rule-list {
    background-color: $main-bg;
    padding: 10px;
    min-height: 200px;
    border: 2px dashed $soft-color;
    .rule-card {
      margin: 10px 0;
      padding: 10px;
      background-color: $dark-bg;
      color: $main-color;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .rule-content {
        display: flex;
        gap: 10px;
        align-items: center;
        flex-grow: 1;
        flex-wrap: wrap; // Ensure content wraps on smaller screens
      }
      .rule-actions {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
  }
  .MuiModal-root {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); // Modal background overlay
    .MuiBox-root {
      background-color: $soft-bg;
      color: $main-color;
      padding: 20px;
      outline: none;
      border-radius: 8px;
      max-width: 500px;
      width: 100%;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      .modal-actions {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
      }
    }
  }
  .review-content {
    margin-top: 20px;
    .review-item {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      .item-label {
        font-weight: bold;
      }
      .item-value {
        flex: 1;
        text-align: right;
        padding-left: 10px;
      }
    }
  }
}
