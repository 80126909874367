.autocomplete-results-container {
  position: absolute;
  width: 100%;
  z-index: 10000; /* Ensure it's on top */
}

.autocomplete-root {
  background-color: #2a3447;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 10px 0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  max-height: none; /* Removed max-height to avoid unnecessary scrolling */
  overflow-y: visible; /* Ensure no scroll */
}

.autocomplete-listItem {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3b4a5a;
  }
}

.autocomplete-icon {
  color: #00bfff;
}

.autocomplete-primaryText {
  font-weight: bold;
  color: silver;
}

.autocomplete-secondaryText {
  color: #a1a1a1;
}

.highlighted-text {
  font-weight: bold;
  color: #ffffff;
}
