// src/pages/budgets/createBudget/BudgetCreate.scss

@import '../../../styles/base/_global';
@import '../../../styles/themes/_variables';

.create-budget-container {
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: $main-bg;

  .paper {
    padding: 24px;
    background-color: white;
    color: #000000;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }

  .submit-button {
    margin-top: 16px;
    background-color: $primary-color;
    color: white;
    width: fit-content;
    padding: 8px 16px;
    align-self: center;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }

  .errorText {
    color: $error-color;
    font-size: 0.875rem;
    margin-top: 4px;
  }
}
