@import '../../styles/themes/_variables.scss';

.toast {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2rem);
  padding: 1rem;
  background-color: #ffffff;
  color: $error-color;
  border: 1px solid darken($error-color, 20%);
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: fadeIn 0.5s ease-in-out;

  span {
    flex-grow: 1;
  }

  .toast-close-button {
    background: none;
    border: none;
    color: $error-color;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-1rem);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}
