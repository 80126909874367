.navbar {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //background-color: #fff;
  //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 10px;
}

.icons {
  display: flex;
  align-items: center;
  gap: 20px;

  .notification {
    position: relative;
    span {
      background-color: red;
      color: white;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      position: absolute;
      top: -10px;
      right: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
  }

  .user {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .language-switcher {
    display: flex;
    align-items: center;

    button {
      margin-left: 10px;
      padding: 5px 10px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  // Loading spinner
  .loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: 3px solid rgba(0, 123, 255, 0.3); /* Light blue border */
    border-top-color: #007bff; /* Blue color for the top */
    border-radius: 50%;
    animation: spin 1s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
