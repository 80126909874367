@import '../../styles/base/_global';
@import '../../styles/themes/_variables';

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;

  .dropdown-toggle {
    background-color: $soft-bg;
    color: $main-color;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
    width: 100%;

    &:hover {
      background-color: lighten($soft-bg, 5%);
    }

    .expand-icon {
      transition: transform 0.3s ease;
      font-size: 20px;
    }

    &:focus {
      outline: none;
      background-color: darken($soft-bg, 5%);
    }

    &.open .expand-icon {
      transform: rotate(180deg);
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $dark-bg;
    border-radius: 5px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    margin-top: 5px;
    z-index: 1000;

    .dropdown-item {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      color: $main-color;

      &:hover {
        background-color: lighten($dark-bg, 5%);
      }

      input[type='radio'] {
        accent-color: $primary-color;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}
