.policy-assign-container {
  padding: 16px;

  .paper {
    padding: 20px;
    border-radius: 12px;
  }

  .section {
    margin-bottom: 20px;
  }

  .section-title {
    font-weight: 700;
    margin-bottom: 8px;
    color: #000;
  }

  .policy-title {
    font-weight: 700;
    margin-bottom: 12px;
    color: #000;
  }

  .policy-description {
    margin-bottom: 16px;
    color: #6b6b6b;
  }

  .policy-rules-list {
    .MuiListItem-root {
      padding: 10px 20px;
      border-radius: 8px;
      background-color: #f9f9f9;
      margin-bottom: 8px;

      &:nth-of-type(even) {
        background-color: #e8f0fe;
      }
    }

    .MuiDivider-root {
      margin: 0;
    }
  }

  .dropdown-container {
    margin-top: 10px;
  }

  .data-grid-container {
    background-color: #fff;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .users-title {
    margin-bottom: 16px;
    font-weight: 700;
    color: #000;
  }

  .MuiFormControl-root {
    margin-top: 16px;
  }

  .MuiButton-root {
    margin-top: 24px;
  }

  .MuiDataGrid-root {
    margin-top: 16px;
    background-color: #fff;
    .MuiDataGrid-cell {
      font-size: 14px;
      color: #333;
    }
    .MuiDataGrid-columnsContainer {
      background-color: #f5f5f5;
    }
  }

  .validation-error {
    color: #f44336;
    font-size: 12px;
    margin-top: 8px;
  }

  @media (max-width: 600px) {
    .paper {
      padding: 12px;
    }
    .MuiFormControl-root {
      margin-top: 12px;
    }
    .MuiButton-root {
      margin-top: 20px;
    }
    .MuiDataGrid-root {
      font-size: 12px;
    }
  }
}
