@import '../../../styles/base/_global';
@import '../../../styles/themes/_variables';

.department-edit-root {
  padding: 24px;
  background-color: lighten($main-bg, 5%);
  color: $main-color;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
  text-align: center;

  .form-title {
    margin-bottom: 20px;
    font-size: 2rem;
    text-align: center;
    padding: 10px 0;
  }

  .department-edit-form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .form-control {
      //margin: 8px 0;

      .textField-label,
      .select-label {
        //color: $soft-color;
      }
    }

    .submit-button {
      margin-top: 16px;
      background-color: $primary-color;
      color: $main-color;
      width: fit-content;
      padding: 8px 16px;
      align-self: center;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }

    .errorText {
      color: $error-color;
    }
  }
}
