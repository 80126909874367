.corporate-list-container {
  font-family: 'Roboto', sans-serif;

  h2 {
    margin-bottom: 20px;
  }

  .create-button {
    margin-bottom: 20px;
  }

  .corporate-list {
    padding: 20px;
    border-radius: 8px;
    background-color: #ffffff;
    width: 100%;
  }

  .MuiDataGrid-root {
    background-color: #ffffff;
    border: none;

    .MuiDataGrid-columnHeaders {
      background-color: #f4f4f4;
      font-weight: bold;
      font-size: 16px;
    }

    .MuiDataGrid-row:hover {
      background-color: #f1f1f1;
    }

    .MuiDataGrid-cell {
      border-bottom: 1px solid #ddd;
      font-size: 14px;
    }
  }
}
