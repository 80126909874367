// src/styles/themes/_variables.scss

// Existing variables
$main-bg: #2a3447;
$soft-bg: #384256;
$dark-bg: #222b3c;

$main-color: white;
$soft-color: #ddd;
$dark-color: #2a3447;

$primary-color: #007bff;
$secondary-color: #6c757d;
$error-color: #f44336;

// New variables based on the design
$button-bg: $primary-color;
$button-bg-hover: darken($primary-color, 10%);
$form-bg: $main-bg;
$input-bg: lighten($dark-bg, 5%);
$input-border: $soft-bg;
